/* eslint-disable no-undef */

/* eslint-disable no-lone-blocks */
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Container from '@src/components/commons/containerPage.common';
import AddHubBannerForm from '@src/components/forms/addHubBanner.form';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';

// import { Select } from 'formik-mui';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const url = `${process.env.REACT_APP_API_URL}/admin/mobile-banners`;
const urls = `${process.env.REACT_APP_API_URL}/event/get-event`;

export default function AddHubBanner() {
  const path = useLocation().pathname;
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState([]);

  const [inputAds, setInputAds] = useState({
    title: '',
    ads_link: [''],
    event_link: [''],
    event_id: [''],
    file: [],
    start_dates: '',
    end_dates: '',
    start_times: '',
    end_times: '',
    statuses: '',
    type: '',
    banner_type: [''],
    sort: '',
  });

  // const handleAdsLinkChange = (value, index) => {
  //   const updatedInputAds = [...inputAds];
  //   updatedInputAds[index].ads_link = value;
  //   setInputAds(updatedInputAds);
  // };

  const handleFileChange = (event, index) => {
    const selectedImages = Array.from(event.target.files);
    setSelectedFile(selectedImages);

    setInputAds((prevInput) => ({
      ...prevInput,
      file: [
        ...prevInput.file.slice(0, index),
        ...selectedImages,
        ...prevInput.file.slice(index + 1),
      ],
    }));

    const updatedPreviewUrl = [...previewUrl];
    selectedImages.forEach((image, i) => {
      updatedPreviewUrl[index + i] = URL.createObjectURL(image);
    });
    setPreviewUrl(updatedPreviewUrl);
  };

  const [open, setOpen] = React.useState(false);

  const [listEvents, setListEvents] = useState([]);
  const [status, setStatus] = useState('listed');
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${urls}?event_status=${status}`, {
          headers: {
            Authorization: `Bearer ${auth?.idToken}`,
          },
        });

        setListEvents(response.data);
      } catch (error) {
        console.log('Error', error.message);
      }
    };
    if (auth?.idToken) {
      fetchData();
    }
  }, [auth]);

  function postMobBanner() {
    const uploadFormData = new FormData();
    uploadFormData.append('ads_title', inputAds.ads_title);
    inputAds.file.forEach((file, index) => {
      uploadFormData.append('files', file);

      if (inputAds.banner_type) {
        if (inputAds.banner_type && inputAds.banner_type) {
          const bannerType = inputAds.banner_type;
          uploadFormData.append(`banner_type`, bannerType);
        }
      }
      if (inputAds.banner_type === 'ads') {
        if (inputAds.ads_link && inputAds.ads_link[index]) {
          const adsLink = inputAds.ads_link[index];
          uploadFormData.append(`ads_link[${index}]`, adsLink);
        }
      } else if (inputAds.banner_type === 'event') {
        if (inputAds.event_id) {
          uploadFormData.append('event_id', inputAds.event_id);
        }
        if (inputAds.event_link && inputAds.event_link[index]) {
          const eventLink = inputAds.event_link[index];
          uploadFormData.append(`event_link[${index}]`, eventLink);
        }
      }
    });
    uploadFormData.append('start_date', inputAds.start_dates);
    uploadFormData.append('end_date', inputAds.end_dates);
    uploadFormData.append('start_time', inputAds.start_times);
    uploadFormData.append('end_time', inputAds.end_times);
    uploadFormData.append('ads_status', inputAds.statuses);
    uploadFormData.append('ads_type', inputAds.type);
    uploadFormData.append('sorts', inputAds.sort);

    console.log(uploadFormData);

    axios
      .post(url, uploadFormData, {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        setInputAds(response.data);
        // console.log(response);
        setOpen(true);
      })
      .catch((error) => {
        console.log('Error', error.message);
      });
  }

  const handleSaveButtonClick = () => {
    // if (!inputAds?.type) {
    //   toast.error('Role is required.');
    //   return;
    // }
    if (!inputAds?.start_dates) {
      toast.error('Start date is required.');
      return;
    }
    if (!inputAds?.end_dates) {
      toast.error('End date is required.');
      return;
    }
    if (!inputAds?.start_times) {
      toast.error('Start time is required.');
      return;
    }
    if (!inputAds?.end_times) {
      toast.error('End time is required.');
      return;
    }
    setTimeout(() => {
      postMobBanner();
    }, 1000);
  };

  const handleBack = () => {
    navigate('/announcement-marketing');
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    navigate('/announcement-marketing');
  };

  return (
    <Container pathUrl={path}>
      <Box sx={{ width: '100%' }}>
        <div className='flex w-3/4 justify-between'>
          <Button
            sx={{
              textTransform: 'none',
              paddingLeft: 0,
            }}
            className='py-6 px-6 font-bold text-purple-500'
            variant='text'
            // eslint-disable-next-line react/jsx-no-bind
            onClick={handleBack}
          >
            Back
          </Button>
          <Button
            sx={{
              textTransform: 'none',
              margin: 1,
              height: '50px',
              width: '150px',
            }}
            className='rounded-md border border-cyan-600 px-6 font-bold text-cyan-600'
            variant='outlined'
            // eslint-disable-next-line react/jsx-no-bind
            onClick={handleSaveButtonClick}
            onNavigate={() => {
              navigate('/announcement-marketing');
              handleOpenModal();
            }}
          >
            Save
          </Button>
        </div>
      </Box>
      <br />
      <Box
        className='w-3/4 rounded-xl'
        sx={{ width: '100%', height: '170px', backgroundColor: 'white' }}
      >
        <AddHubBannerForm inputAds={inputAds} setInputAds={setInputAds} />
      </Box>

      <div className='py-4 px-4'>
        <div className='py-2 text-sm font-bold text-gray-500'>
          Home Screen Carousel Banner
        </div>
        <div className='text-xs text-gray-400'>(Best size 938px by 198px)</div>
      </div>
      <br />
      <Box
        sx={{
          width: '100%',
          backgroundColor: 'white',
          overflowX: 'auto',
          overflowY: 'hidden',
          scrollbarWidth: 'thin',
          scrollbarColor: 'gray',
        }}
        className='w-3/4 rounded-xl py-4'
      >
        <div className='flex' style={{ width: 'calc(695px * 5)' }}>
          <Box
            sx={{
              margin: 2,
              width: '655px',
              height: 'auto',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                margin: 2,
                width: '635px',
                height: '135px',
                position: 'relative',
                overflow: 'hidden',
              }}
              className='flex justify-center bg-gray-300'
            >
              <div>
                <input
                  type='file'
                  accept='image/*'
                  onChange={(e) => handleFileChange(e, 0)}
                  name='files'
                  multiple
                  style={{ display: 'none' }}
                  id='upload-file-input1'
                />

                {selectedFile && (
                  <div>
                    <img
                      src={previewUrl[0]}
                      alt=''
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        objectPosition: 'center',
                      }}
                    />
                  </div>
                )}
                <label htmlFor='upload-file-input1'>
                  <Button
                    sx={{
                      textTransform: 'none',
                      position: 'absolute',
                      top: '50%',
                      left: '30%',
                      transform: 'translate(-50%, -50%)',
                      zIndex: 1,
                    }}
                    variant='contained'
                    component='span'
                    className='bg-gray-400 text-xs font-bold underline'
                  >
                    Upload
                  </Button>
                  <br />
                </label>
              </div>
            </Box>

            <div className='col-span-4 px-4'>
              <label className='block py-2 text-sm font-medium leading-6 text-gray-600'>
                Banner type*
              </label>

              <FormControl>
                <Select
                  id='type'
                  style={{ height: '48px', width: '635px', fontSize: 14 }}
                  onChange={(e) => {
                    if (inputAds) {
                      setInputAds({
                        ...inputAds,
                        banner_type: e.target.value,
                      });
                    }
                  }}
                  className='rounded-md text-gray-600'
                >
                  <MenuItem disabled value=''>
                    <em style={{ color: '#8C8C8C' }}>Select type</em>
                  </MenuItem>

                  <MenuItem value='ads'>Ads</MenuItem>
                  <MenuItem value='event'>Event</MenuItem>
                </Select>
              </FormControl>

              {inputAds?.banner_type === 'ads' && (
                <div className='mt-2 py-4'>
                  <input
                    id='ads'
                    placeholder='Enter link'
                    value={(inputAds.ads_link && inputAds.ads_link[0]) || ''}
                    onChange={(e) => {
                      const updatedAdsLink = [...(inputAds.ads_link || [])];
                      updatedAdsLink[0] = e.target.value;
                      setInputAds({ ...inputAds, ads_link: updatedAdsLink });
                    }}
                    className='font-poppins block w-full rounded-md border-0 bg-white px-2 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                  />
                </div>
              )}

              {inputAds?.banner_type === 'event' && (
                <>
                  <div className='py-6'>
                    <FormControl>
                      <Select
                        id='event'
                        style={{ height: '48px', width: '635px', fontSize: 14 }}
                        onChange={(e) => {
                          if (inputAds) {
                            setInputAds({
                              ...inputAds,
                              event_id: e.target.value,
                            });
                          }
                        }}
                        value={inputAds?.event_id}
                        className='rounded-md text-gray-600'
                      >
                        <MenuItem disabled value=''>
                          <em style={{ color: '#8C8C8C' }}>Select event</em>
                        </MenuItem>
                        {listEvents?.map((option) => (
                          <MenuItem key={option.value} value={option._id}>
                            {option.event_title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div>
                    <input
                      id='event'
                      placeholder='Enter link'
                      value={
                        (inputAds.event_link && inputAds.event_link[0]) || ''
                      }
                      onChange={(e) => {
                        const updatedAdsLink = [...(inputAds.event_link || [])];
                        updatedAdsLink[0] = e.target.value;
                        setInputAds({
                          ...inputAds,
                          event_link: updatedAdsLink,
                        });
                      }}
                      className='font-poppins block w-full rounded-md border-0 bg-white px-2 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                    />
                  </div>
                </>
              )}
            </div>
          </Box>

          <Box
            sx={{
              margin: 2,
              width: '655px',
              height: 'auto',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                margin: 2,
                width: '635px',
                height: '135px',
                position: 'relative',
                overflow: 'hidden',
              }}
              className='flex justify-center bg-gray-300'
            >
              <div>
                <input
                  type='file'
                  accept='image/*'
                  onChange={(e) => handleFileChange(e, 1)}
                  name='files'
                  multiple
                  style={{ display: 'none' }}
                  id='upload-file-input2'
                />

                {selectedFile && (
                  <div>
                    <img
                      src={previewUrl[1]}
                      alt=''
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        objectPosition: 'center',
                      }}
                    />
                  </div>
                )}
                <label htmlFor='upload-file-input2'>
                  <Button
                    sx={{
                      textTransform: 'none',
                      position: 'absolute',
                      top: '50%',
                      left: '30%',
                      transform: 'translate(-50%, -50%)',
                      zIndex: 1,
                    }}
                    variant='contained'
                    component='span'
                    className='bg-gray-400 text-xs font-bold underline'
                  >
                    Upload
                  </Button>
                  <br />
                </label>
              </div>
            </Box>

            <div className='col-span-4 px-4'>
              <label className='block py-2 text-sm font-medium leading-6 text-gray-600'>
                Banner type*
              </label>

              <FormControl>
                <Select
                  id='type'
                  style={{ height: '48px', width: '635px', fontSize: 14 }}
                  onChange={(e) => {
                    if (inputAds) {
                      setInputAds({
                        ...inputAds,
                        banner_type: e.target.value,
                      });
                    }
                  }}
                  className='rounded-md text-gray-600'
                >
                  <MenuItem disabled value=''>
                    <em style={{ color: '#8C8C8C' }}>Select type</em>
                  </MenuItem>

                  <MenuItem value='ads'>Ads</MenuItem>
                  <MenuItem value='event'>Event</MenuItem>
                </Select>
              </FormControl>

              {inputAds?.banner_type === 'ads' && (
                <div className='mt-2 py-4'>
                  <input
                    id='ads'
                    placeholder='Enter link'
                    value={(inputAds.ads_link && inputAds.ads_link[1]) || ''}
                    onChange={(e) => {
                      const updatedAdsLink = [...(inputAds.ads_link || [])];
                      updatedAdsLink[1] = e.target.value;
                      setInputAds({ ...inputAds, ads_link: updatedAdsLink });
                    }}
                    className='font-poppins block w-full rounded-md border-0 bg-white px-2 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                  />
                </div>
              )}

              {inputAds?.banner_type === 'event' && (
                <>
                  <div className='py-6'>
                    <FormControl>
                      <Select
                        id='event'
                        style={{ height: '48px', width: '635px', fontSize: 14 }}
                        onChange={(e) => {
                          if (inputAds) {
                            setInputAds({
                              ...inputAds,
                              event_id: e.target.value,
                            });
                          }
                        }}
                        value={inputAds?.event_id}
                        className='rounded-md text-gray-600'
                      >
                        <MenuItem disabled value=''>
                          <em style={{ color: '#8C8C8C' }}>Select event</em>
                        </MenuItem>
                        {listEvents?.map((option) => (
                          <MenuItem key={option.value} value={option._id}>
                            {option.event_title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div>
                    <input
                      id='event'
                      placeholder='Enter link'
                      value={
                        (inputAds.event_link && inputAds.event_link[1]) || ''
                      }
                      onChange={(e) => {
                        const updatedAdsLink = [...(inputAds.event_link || [])];
                        updatedAdsLink[1] = e.target.value;
                        setInputAds({
                          ...inputAds,
                          event_link: updatedAdsLink,
                        });
                      }}
                      className='font-poppins block w-full rounded-md border-0 bg-white px-2 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                    />
                  </div>
                </>
              )}
            </div>
          </Box>

          <Box
            sx={{
              margin: 2,
              width: '655px',
              height: 'auto',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                margin: 2,
                width: '635px',
                height: '135px',
                position: 'relative',
                overflow: 'hidden',
              }}
              className='flex justify-center bg-gray-300'
            >
              <div>
                <input
                  type='file'
                  accept='image/*'
                  onChange={(e) => handleFileChange(e, 2)}
                  name='files'
                  multiple
                  style={{ display: 'none' }}
                  id='upload-file-input3'
                />

                {selectedFile && (
                  <div>
                    <img
                      src={previewUrl[2]}
                      alt=''
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        objectPosition: 'center',
                      }}
                    />
                  </div>
                )}
                <label htmlFor='upload-file-input3'>
                  <Button
                    sx={{
                      textTransform: 'none',
                      position: 'absolute',
                      top: '50%',
                      left: '30%',
                      transform: 'translate(-50%, -50%)',
                      zIndex: 1,
                    }}
                    variant='contained'
                    component='span'
                    className='bg-gray-400 text-xs font-bold underline'
                  >
                    Upload
                  </Button>
                  <br />
                </label>
              </div>
            </Box>

            <div className='col-span-4 px-4'>
              <label className='block py-2 text-sm font-medium leading-6 text-gray-600'>
                Banner type*
              </label>

              <FormControl>
                <Select
                  id='type'
                  style={{ height: '48px', width: '635px', fontSize: 14 }}
                  onChange={(e) => {
                    if (inputAds) {
                      setInputAds({
                        ...inputAds,
                        banner_type: e.target.value,
                      });
                    }
                  }}
                  className='rounded-md text-gray-600'
                >
                  <MenuItem disabled value=''>
                    <em style={{ color: '#8C8C8C' }}>Select type</em>
                  </MenuItem>

                  <MenuItem value='ads'>Ads</MenuItem>
                  <MenuItem value='event'>Event</MenuItem>
                </Select>
              </FormControl>

              {inputAds?.banner_type === 'ads' && (
                <div className='mt-2 py-4'>
                  <input
                    id='ads'
                    placeholder='Enter link'
                    value={(inputAds.ads_link && inputAds.ads_link[2]) || ''}
                    onChange={(e) => {
                      const updatedAdsLink = [...(inputAds.ads_link || [])];
                      updatedAdsLink[2] = e.target.value;
                      setInputAds({ ...inputAds, ads_link: updatedAdsLink });
                    }}
                    className='font-poppins block w-full rounded-md border-0 bg-white px-2 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                  />
                </div>
              )}

              {inputAds?.banner_type === 'event' && (
                <>
                  <div className='py-6'>
                    <FormControl>
                      <Select
                        id='event'
                        style={{ height: '48px', width: '635px', fontSize: 14 }}
                        onChange={(e) => {
                          if (inputAds) {
                            setInputAds({
                              ...inputAds,
                              event_id: e.target.value,
                            });
                          }
                        }}
                        value={inputAds?.event_id}
                        className='rounded-md text-gray-600'
                      >
                        <MenuItem disabled value=''>
                          <em style={{ color: '#8C8C8C' }}>Select event</em>
                        </MenuItem>
                        {listEvents?.map((option) => (
                          <MenuItem key={option.value} value={option._id}>
                            {option.event_title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div>
                    <input
                      id='event'
                      placeholder='Enter link'
                      value={
                        (inputAds.event_link && inputAds.event_link[2]) || ''
                      }
                      onChange={(e) => {
                        const updatedAdsLink = [...(inputAds.event_link || [])];
                        updatedAdsLink[2] = e.target.value;
                        setInputAds({
                          ...inputAds,
                          event_link: updatedAdsLink,
                        });
                      }}
                      className='font-poppins block w-full rounded-md border-0 bg-white px-2 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                    />
                  </div>
                </>
              )}
            </div>
          </Box>

          <Box
            sx={{
              margin: 2,
              width: '655px',
              height: 'auto',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                margin: 2,
                width: '635px',
                height: '135px',
                position: 'relative',
                overflow: 'hidden',
              }}
              className='flex justify-center bg-gray-300'
            >
              <div>
                <input
                  type='file'
                  accept='image/*'
                  onChange={(e) => handleFileChange(e, 3)}
                  name='files'
                  multiple
                  style={{ display: 'none' }}
                  id='upload-file-input4'
                />

                {selectedFile && (
                  <div>
                    <img
                      src={previewUrl[3]}
                      alt=''
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        objectPosition: 'center',
                      }}
                    />
                  </div>
                )}
                <label htmlFor='upload-file-input4'>
                  <Button
                    sx={{
                      textTransform: 'none',
                      position: 'absolute',
                      top: '50%',
                      left: '30%',
                      transform: 'translate(-50%, -50%)',
                      zIndex: 1,
                    }}
                    variant='contained'
                    component='span'
                    className='bg-gray-400 text-xs font-bold underline'
                  >
                    Upload
                  </Button>
                  <br />
                </label>
              </div>
            </Box>
            <div className='col-span-4 px-4'>
              <label className='block py-2 text-sm font-medium leading-6 text-gray-600'>
                Banner type*
              </label>

              <FormControl>
                <Select
                  id='type'
                  style={{ height: '48px', width: '635px', fontSize: 14 }}
                  onChange={(e) => {
                    if (inputAds) {
                      setInputAds({
                        ...inputAds,
                        banner_type: e.target.value,
                      });
                    }
                  }}
                  className='rounded-md text-gray-600'
                >
                  <MenuItem disabled value=''>
                    <em style={{ color: '#8C8C8C' }}>Select type</em>
                  </MenuItem>

                  <MenuItem value='ads'>Ads</MenuItem>
                  <MenuItem value='event'>Event</MenuItem>
                </Select>
              </FormControl>

              {inputAds?.banner_type === 'ads' && (
                <div className='mt-2 py-4'>
                  <input
                    id='ads'
                    placeholder='Enter link'
                    value={(inputAds.ads_link && inputAds.ads_link[3]) || ''}
                    onChange={(e) => {
                      const updatedAdsLink = [...(inputAds.ads_link || [])];
                      updatedAdsLink[3] = e.target.value;
                      setInputAds({ ...inputAds, ads_link: updatedAdsLink });
                    }}
                    className='font-poppins block w-full rounded-md border-0 bg-white px-2 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                  />
                </div>
              )}

              {inputAds?.banner_type === 'event' && (
                <>
                  <div className='py-6'>
                    <FormControl>
                      <Select
                        id='event'
                        style={{ height: '48px', width: '635px', fontSize: 14 }}
                        onChange={(e) => {
                          if (inputAds) {
                            setInputAds({
                              ...inputAds,
                              event_id: e.target.value,
                            });
                          }
                        }}
                        value={inputAds?.event_id}
                        className='rounded-md text-gray-600'
                      >
                        <MenuItem disabled value=''>
                          <em style={{ color: '#8C8C8C' }}>Select event</em>
                        </MenuItem>
                        {listEvents?.map((option) => (
                          <MenuItem key={option.value} value={option._id}>
                            {option.event_title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div>
                    <input
                      id='event'
                      placeholder='Enter link'
                      value={
                        (inputAds.event_link && inputAds.event_link[3]) || ''
                      }
                      onChange={(e) => {
                        const updatedAdsLink = [...(inputAds.event_link || [])];
                        updatedAdsLink[3] = e.target.value;
                        setInputAds({
                          ...inputAds,
                          event_link: updatedAdsLink,
                        });
                      }}
                      className='font-poppins block w-full rounded-md border-0 bg-white px-2 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                    />
                  </div>
                </>
              )}
            </div>
          </Box>

          <Box
            sx={{
              margin: 2,
              width: '655px',
              height: 'auto',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                margin: 2,
                width: '635px',
                height: '135px',
                position: 'relative',
                overflow: 'hidden',
              }}
              className='flex justify-center bg-gray-300'
            >
              <div>
                <input
                  type='file'
                  accept='image/*'
                  onChange={(e) => handleFileChange(e, 4)}
                  name='files'
                  multiple
                  style={{ display: 'none' }}
                  id='upload-file-input5'
                />

                {selectedFile && (
                  <div>
                    <img
                      src={previewUrl[4]}
                      alt=''
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        objectPosition: 'center',
                      }}
                    />
                  </div>
                )}
                <label htmlFor='upload-file-input5'>
                  <Button
                    sx={{
                      textTransform: 'none',
                      position: 'absolute',
                      top: '50%',
                      left: '30%',
                      transform: 'translate(-50%, -50%)',
                      zIndex: 1,
                    }}
                    variant='contained'
                    component='span'
                    className='bg-gray-400 text-xs font-bold underline'
                  >
                    Upload
                  </Button>
                  <br />
                </label>
              </div>
            </Box>
            <div className='col-span-4 px-4'>
              <label className='block py-2 text-sm font-medium leading-6 text-gray-600'>
                Banner type*
              </label>

              <FormControl>
                <Select
                  id='type'
                  style={{ height: '48px', width: '635px', fontSize: 14 }}
                  onChange={(e) => {
                    if (inputAds) {
                      setInputAds({
                        ...inputAds,
                        banner_type: e.target.value,
                      });
                    }
                  }}
                  className='rounded-md text-gray-600'
                >
                  <MenuItem disabled value=''>
                    <em style={{ color: '#8C8C8C' }}>Select type</em>
                  </MenuItem>

                  <MenuItem value='ads'>Ads</MenuItem>
                  <MenuItem value='event'>Event</MenuItem>
                </Select>
              </FormControl>

              {inputAds?.banner_type === 'ads' && (
                <div className='mt-2 py-4'>
                  <input
                    id='ads'
                    placeholder='Enter link'
                    value={(inputAds.ads_link && inputAds.ads_link[4]) || ''}
                    onChange={(e) => {
                      const updatedAdsLink = [...(inputAds.ads_link || [])];
                      updatedAdsLink[4] = e.target.value;
                      setInputAds({ ...inputAds, ads_link: updatedAdsLink });
                    }}
                    className='font-poppins block w-full rounded-md border-0 bg-white px-2 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                  />
                </div>
              )}

              {inputAds?.banner_type === 'event' && (
                <>
                  <div className='py-6'>
                    <FormControl>
                      <Select
                        id='event'
                        style={{ height: '48px', width: '635px', fontSize: 14 }}
                        onChange={(e) => {
                          if (inputAds) {
                            setInputAds({
                              ...inputAds,
                              event_id: e.target.value,
                            });
                          }
                        }}
                        value={inputAds?.event_id}
                        className='rounded-md text-gray-600'
                      >
                        <MenuItem disabled value=''>
                          <em style={{ color: '#8C8C8C' }}>Select event</em>
                        </MenuItem>
                        {listEvents?.map((option) => (
                          <MenuItem key={option.value} value={option._id}>
                            {option.event_title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div>
                    <input
                      id='event'
                      placeholder='Enter link'
                      value={
                        (inputAds.event_link && inputAds.event_link[4]) || ''
                      }
                      onChange={(e) => {
                        const updatedAdsLink = [...(inputAds.event_link || [])];
                        updatedAdsLink[4] = e.target.value;
                        setInputAds({
                          ...inputAds,
                          event_link: updatedAdsLink,
                        });
                      }}
                      className='font-poppins block w-full rounded-md border-0 bg-white px-2 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                    />
                  </div>
                </>
              )}
            </div>
          </Box>
        </div>
        <br />
        <div className='px-8 text-xs text-gray-300'>*image is required</div>
      </Box>

      <br />
      <br />
      <br />

      <Dialog open={open} onClose={handleCloseModal}>
        <DialogTitle>Save Successful</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The banner has been created successfully.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
