import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@src/components/commons/containerPage.common';
import AddWebCampaignForm from '@src/components/forms/addWebCampaign.form';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';

import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const url = `${process.env.REACT_APP_API_URL}/admin/web-banners`;

export default function AddWebCampaign() {
  const path = useLocation().pathname;
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState([]);

  const [input, setInput] = useState({
    title: '',
    campaign_link: [],
    file: [],
    start_dates: '',
    end_dates: '',
    start_times: '',
    end_times: '',
    status: ['ongoing', 'coming_soon', 'expired', 'pause'],
    sort: '',
  });

  const [successModalOpen, setSuccessModalOpen] = useState(false);

  const handleFileChange = (event, index) => {
    const selectedImages = Array.from(event.target.files);
    setSelectedFile(selectedImages);

    setInput((prevInput) => ({
      ...prevInput,
      file: [
        ...prevInput.file.slice(0, index),
        ...selectedImages,
        ...prevInput.file.slice(index + 1),
      ],
    }));

    const updatedPreviewUrl = [...previewUrl];
    selectedImages.forEach((image, i) => {
      updatedPreviewUrl[index + i] = URL.createObjectURL(image);
    });
    setPreviewUrl(updatedPreviewUrl);
  };

  // const handleDelete = () => {
  //   setSelectedFile(null);
  //   setPreviewUrl([]);
  // };

  // useEffect(() => {
  //   axios
  //     .get(url, {
  //       headers: {
  //         Authorization: `Bearer ${auth.idToken}`,
  //       },
  //     })
  //     .then((response) => {
  //       setCreate(response.data);
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching data:', error);
  //     });
  // }, [auth]);

  const [open, setOpen] = React.useState(false);

  function postWebBanner() {
    const uploadFormData = new FormData();
    uploadFormData.append('campaign_title', input.campaign_title);
    input.file.forEach((file, index) => {
      uploadFormData.append('files', file);

      if (input.campaign_link && input.campaign_link.length > index) {
        const campLink = input?.campaign_link[index] || '';
        uploadFormData.append(`campaign_link[${index}]`, campLink);
      }
    });
    uploadFormData.append('start_date', input.start_dates);
    uploadFormData.append('end_date', input.end_dates);
    uploadFormData.append('start_time', input.start_times);
    uploadFormData.append('end_time', input.end_times);
    uploadFormData.append('campaign_status', input.campaign_status);
    uploadFormData.append('sorts', input.sort);

    axios
      .post(url, uploadFormData, {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        setInput(response.data);
        console.log(response);
        setOpen(true);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.headers);
          console.log(error.response.status);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
      });
  }

  const handleSaveButtonClick = () => {
    if (!input?.start_dates) {
      toast.error('Start date is required.');
      return;
    }
    if (!input?.end_dates) {
      toast.error('End date is required.');
      return;
    }
    if (!input?.start_times) {
      toast.error('Start time is required.');
      return;
    }
    if (!input?.end_times) {
      toast.error('End time is required.');
      return;
    }
    setTimeout(() => {
      postWebBanner();
    }, 1000);
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    navigate('/announcement-marketing');
  };

  const handleBack = () => {
    navigate('/announcement-marketing');
  };

  return (
    <Container pathUrl={path}>
      <Box sx={{ width: '100%' }}>
        <div className='flex w-3/4 justify-between'>
          <Button
            sx={{
              textTransform: 'none',
              paddingLeft: 0,
            }}
            className='py-6 px-6 font-bold text-purple-500'
            variant='text'
            // eslint-disable-next-line react/jsx-no-bind
            onClick={handleBack}
          >
            Back
          </Button>
          <Button
            sx={{
              textTransform: 'none',
              margin: 1,
              height: '50px',
              width: '150px',
            }}
            className='rounded-md border border-cyan-600 px-6 font-bold text-cyan-600'
            variant='outlined'
            // eslint-disable-next-line react/jsx-no-bind
            onClick={handleSaveButtonClick}
            onNavigate={() => {
              navigate('/announcement-marketing');
              handleOpenModal();
            }}
          >
            Save
          </Button>
        </div>
      </Box>
      <br />
      <Box
        className='w-3/4 rounded-xl py-4'
        sx={{ width: '100%', height: '151px', backgroundColor: 'white' }}
      >
        <AddWebCampaignForm input={input} setInput={setInput} />
      </Box>

      <div className='py-4 px-4'>
        <div className='py-2 text-sm font-bold text-gray-500'>
          Horizontal Desktop Screen Admin
        </div>
        <div className='text-xs text-gray-400'>(Best size 938px by 198px)</div>
      </div>
      <br />
      <Box
        sx={{
          width: '100%',
          backgroundColor: 'white',
          overflowX: 'auto',
          overflowY: 'hidden',
          scrollbarWidth: 'thin',
          scrollbarColor: 'gray',
        }}
        className='w-3/4 rounded-xl py-4'
      >
        <div className='flex' style={{ width: 'calc(695px * 5)' }}>
          <Box
            sx={{
              margin: 2,
              width: '695px',
              height: '296px',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                margin: 2,
                width: '635px',
                height: '135px',
                position: 'relative',
                overflow: 'hidden',
              }}
              className='bg-gray-300'
            >
              <div>
                <input
                  type='file'
                  accept='image/*'
                  onChange={(e) => handleFileChange(e, 0)}
                  name='files'
                  multiple
                  style={{ display: 'none' }}
                  id='upload-file-input2'
                />

                {selectedFile && (
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      position: 'relative',
                    }}
                  >
                    <img
                      src={previewUrl[0]}
                      alt=''
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        objectPosition: 'center',
                      }}
                    />
                  </div>
                )}
                <label htmlFor='upload-file-input2'>
                  <Button
                    sx={{
                      textTransform: 'none',
                      position: 'absolute',
                      top: '50%',
                      left: '30%',
                      transform: 'translate(-50%, -50%)',
                      zIndex: 1,
                    }}
                    variant='contained'
                    component='span'
                    className='bg-gray-400 text-xs font-bold underline'
                  >
                    Upload
                  </Button>
                  <br />
                  {/* <Button
                  sx={{
                    textTransform: 'none',
                    position: 'absolute',
                    top: '50%',
                    left: '70%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 1,
                  }}
                  variant="contained"
                  component="span"
                  className="bg-gray-400 font-bold text-xs underline"
                  onClick={handleDelete}
                >
                  Remove
                </Button> */}
                </label>
              </div>
            </Box>
            <div className='col-span-4  px-4'>
              <label className='block text-sm font-medium leading-6 text-gray-600'>
                Campaign Link
              </label>
              <div className='mt-2'>
                <input
                  placeholder='Enter Link'
                  value={(input.campaign_link && input.campaign_link[0]) || ''}
                  onChange={(e) => {
                    const updatedLink = [...input.campaign_link];
                    updatedLink[0] = e.target.value;
                    setInput({ ...input, campaign_link: updatedLink });
                  }}
                  className='font-poppins block w-full rounded-md border-0 bg-white py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                />
              </div>
            </div>
          </Box>

          <Box
            sx={{
              margin: 2,
              width: '695px',
              height: '296px',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                margin: 2,
                width: '635px',
                height: '135px',
                position: 'relative',
                overflow: 'hidden',
              }}
              className='bg-gray-300'
            >
              <div>
                <input
                  type='file'
                  accept='image/*'
                  onChange={(e) => handleFileChange(e, 1)}
                  name='files'
                  multiple
                  style={{ display: 'none' }}
                  id='upload-file-input'
                />

                {selectedFile && (
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      position: 'relative',
                    }}
                  >
                    <img
                      src={previewUrl[1]}
                      alt=''
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        objectPosition: 'center',
                      }}
                    />
                  </div>
                )}
                <label htmlFor='upload-file-input'>
                  <Button
                    sx={{
                      textTransform: 'none',
                      position: 'absolute',
                      top: '50%',
                      left: '30%',
                      transform: 'translate(-50%, -50%)',
                      zIndex: 1,
                    }}
                    variant='contained'
                    component='span'
                    className='bg-gray-400 text-xs font-bold underline'
                  >
                    Upload
                  </Button>
                  <br />
                  {/* <Button
                  sx={{
                    textTransform: 'none',
                    position: 'absolute',
                    top: '50%',
                    left: '70%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 1,
                  }}
                  variant='contained'
                  component='span'
                  className='bg-gray-400 text-xs font-bold underline'
                  onClick={handleDelete}
                >
                  Remove
                </Button> */}
                </label>
              </div>
            </Box>
            <div className='col-span-4  px-4'>
              <label className='block text-sm font-medium leading-6 text-gray-600'>
                Campaign Link
              </label>
              <div className='mt-2'>
                <input
                  placeholder='Enter Link'
                  value={(input.campaign_link && input.campaign_link[1]) || ''}
                  onChange={(e) => {
                    const updatedLink = [...input.campaign_link];
                    updatedLink[1] = e.target.value;
                    setInput({ ...input, campaign_link: updatedLink });
                  }}
                  className='font-poppins block w-full rounded-md border-0 bg-white py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                />
              </div>
            </div>
          </Box>

          <Box
            sx={{
              margin: 2,
              width: '695px',
              height: '296px',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                margin: 2,
                width: '635px',
                height: '135px',
                position: 'relative',
                overflow: 'hidden',
              }}
              className='bg-gray-300'
            >
              <div>
                <input
                  type='file'
                  accept='image/*'
                  onChange={(e) => handleFileChange(e, 2)}
                  name='files'
                  multiple
                  style={{ display: 'none' }}
                  id='upload-file-input3'
                />

                {selectedFile && (
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      position: 'relative',
                    }}
                  >
                    <img
                      src={previewUrl[2]}
                      alt=''
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        objectPosition: 'center',
                      }}
                    />
                  </div>
                )}
                <label htmlFor='upload-file-input3'>
                  <Button
                    sx={{
                      textTransform: 'none',
                      position: 'absolute',
                      top: '50%',
                      left: '30%',
                      transform: 'translate(-50%, -50%)',
                      zIndex: 1,
                    }}
                    variant='contained'
                    component='span'
                    className='bg-gray-400 text-xs font-bold underline'
                  >
                    Upload
                  </Button>
                  <br />
                  {/* <Button
                  sx={{
                    textTransform: 'none',
                    position: 'absolute',
                    top: '50%',
                    left: '70%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 1,
                  }}
                  variant="contained"
                  component="span"
                  className="bg-gray-400 font-bold text-xs underline"
                  onClick={handleDelete}
                >
                  Remove
                </Button> */}
                </label>
              </div>
            </Box>
            <div className='col-span-4  px-4'>
              <label className='block text-sm font-medium leading-6 text-gray-600'>
                Campaign Link
              </label>
              <div className='mt-2'>
                <input
                  placeholder='Enter Link'
                  value={(input.campaign_link && input.campaign_link[2]) || ''}
                  onChange={(e) => {
                    const updatedLink = [...input.campaign_link];
                    updatedLink[2] = e.target.value;
                    setInput({ ...input, campaign_link: updatedLink });
                  }}
                  className='font-poppins block w-full rounded-md border-0 bg-white py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                />
              </div>
            </div>
          </Box>

          <Box
            sx={{
              margin: 2,
              width: '695px',
              height: '296px',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                margin: 2,
                width: '635px',
                height: '135px',
                position: 'relative',
                overflow: 'hidden',
              }}
              className='bg-gray-300'
            >
              <div>
                <input
                  type='file'
                  accept='image/*'
                  onChange={(e) => handleFileChange(e, 3)}
                  name='files'
                  multiple
                  style={{ display: 'none' }}
                  id='upload-file-input4'
                />

                {selectedFile && (
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      position: 'relative',
                    }}
                  >
                    <img
                      src={previewUrl[3]}
                      alt=''
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        objectPosition: 'center',
                      }}
                    />
                  </div>
                )}
                <label htmlFor='upload-file-input4'>
                  <Button
                    sx={{
                      textTransform: 'none',
                      position: 'absolute',
                      top: '50%',
                      left: '30%',
                      transform: 'translate(-50%, -50%)',
                      zIndex: 1,
                    }}
                    variant='contained'
                    component='span'
                    className='bg-gray-400 text-xs font-bold underline'
                  >
                    Upload
                  </Button>
                  <br />
                  {/* <Button
                  sx={{
                    textTransform: 'none',
                    position: 'absolute',
                    top: '50%',
                    left: '70%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 1,
                  }}
                  variant="contained"
                  component="span"
                  className="bg-gray-400 font-bold text-xs underline"
                  onClick={handleDelete}
                >
                  Remove
                </Button> */}
                </label>
              </div>
            </Box>
            <div className='col-span-4  px-4'>
              <label className='block text-sm font-medium leading-6 text-gray-600'>
                Campaign Link
              </label>
              <div className='mt-2'>
                <input
                  placeholder='Enter Link'
                  value={(input.campaign_link && input.campaign_link[3]) || ''}
                  onChange={(e) => {
                    const updatedLink = [...input.campaign_link];
                    updatedLink[3] = e.target.value;
                    setInput({ ...input, campaign_link: updatedLink });
                  }}
                  className='font-poppins block w-full rounded-md border-0 bg-white py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                />
              </div>
            </div>
          </Box>

          <Box
            sx={{
              margin: 2,
              width: '695px',
              height: '296px',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                margin: 2,
                width: '635px',
                height: '135px',
                position: 'relative',
                overflow: 'hidden',
              }}
              className='bg-gray-300'
            >
              <div>
                <input
                  type='file'
                  accept='image/*'
                  onChange={(e) => handleFileChange(e, 4)}
                  name='files'
                  multiple
                  style={{ display: 'none' }}
                  id='upload-file-input5'
                />

                {selectedFile && (
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      position: 'relative',
                    }}
                  >
                    <img
                      src={previewUrl[4]}
                      alt=''
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        objectPosition: 'center',
                      }}
                    />
                  </div>
                )}
                <label htmlFor='upload-file-input5'>
                  <Button
                    sx={{
                      textTransform: 'none',
                      position: 'absolute',
                      top: '50%',
                      left: '30%',
                      transform: 'translate(-50%, -50%)',
                      zIndex: 1,
                    }}
                    variant='contained'
                    component='span'
                    className='bg-gray-400 text-xs font-bold underline'
                  >
                    Upload
                  </Button>
                  <br />
                  {/* <Button
                  sx={{
                    textTransform: 'none',
                    position: 'absolute',
                    top: '50%',
                    left: '70%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 1,
                  }}
                  variant="contained"
                  component="span"
                  className="bg-gray-400 font-bold text-xs underline"
                  onClick={handleDelete}
                >
                  Remove
                </Button> */}
                </label>
              </div>
            </Box>
            <div className='col-span-4  px-4'>
              <label className='block text-sm font-medium leading-6 text-gray-600'>
                Campaign Link
              </label>
              <div className='mt-2'>
                <input
                  placeholder='Enter Link'
                  value={(input.campaign_link && input.campaign_link[4]) || ''}
                  onChange={(e) => {
                    const updatedLink = [...input.campaign_link];
                    updatedLink[4] = e.target.value;
                    setInput({ ...input, campaign_link: updatedLink });
                  }}
                  className='font-poppins block w-full rounded-md border-0 bg-white py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                />
              </div>
            </div>
          </Box>
        </div>

        <div className='px-8 text-xs text-gray-300'>*image is required</div>
      </Box>
      <br />
      <br />
      <br />

      <Dialog open={open} onClose={handleCloseModal}>
        <DialogTitle>Save Successful</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The campaign has been created successfully.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
