import '@fontsource/poppins';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';

import React, { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function CreateAdminOrg({ createAdmin, setCreateAdmin }) {
  const [previousEmail, setPreviousEmail] = useState('');

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setCreateAdmin({
      ...createAdmin,
      email: newEmail,
    });

    if (newEmail === previousEmail) {
      toast.error('Email cannot be the same as the previous one', {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 sm:grid-cols-6'>
        <div className='sm:col-span-6'>
          <label className='text-md block font-bold leading-6 text-gray-600'>
            Contact Person*
          </label>
          <div className='font-poppins mt-2 font-medium'>
            <input
              placeholder='Enter name'
              id='name'
              type='text'
              style={{ width: '470px' }}
              className='font-poppins focus:ring- -600 block w-full rounded-md border-0 bg-gray-100 px-3 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1
              focus:ring-inset sm:text-sm sm:leading-6'
              value={createAdmin?.name || ''}
              onChange={(e) =>
                setCreateAdmin({
                  ...createAdmin,
                  name: e.target.value,
                })
              }
            />
          </div>
        </div>
      </div>

      <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 py-1 sm:grid-cols-6'>
        <div className='sm:col-span-6'>
          <label className='text-md block font-bold leading-6 text-gray-600'>
            Email*
          </label>
          <div className='mt-2 font-medium'>
            <input
              id='email'
              placeholder='Enter email'
              type='email'
              style={{ width: '470px' }}
              className='font-poppins focus:ring- -600 block w-full rounded-md border-0 bg-gray-100 px-3 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1
              focus:ring-inset sm:text-sm sm:leading-6'
              value={createAdmin?.email}
              onChange={handleEmailChange}
            />
          </div>
        </div>
      </div>
      <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 sm:grid-cols-6'>
        <div className='py-1 sm:col-span-6'>
          <label className='text-md block font-bold leading-6 text-gray-600'>
            Password for Account*
          </label>
          <FormControl className='py-2' sx={{ width: '49ch' }}>
            <OutlinedInput
              className='flex-1 rounded-md border-0 bg-gray-100 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset sm:text-sm sm:leading-6'
              placeholder='Enter password'
              id='password'
              type={showPassword ? 'text' : 'password'}
              value={createAdmin?.password || ''}
              onChange={(e) =>
                setCreateAdmin({
                  ...createAdmin,
                  password: e.target.value,
                })
              }
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </div>
      </div>
    </>
  );
}
