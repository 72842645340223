import { Box, Button, TextField, Typography } from '@mui/material';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';

import React, { useState } from 'react';
import { toast } from 'react-toastify';

const url = `${process.env.REACT_APP_API_URL}/admin/skill-items`;

export default function AddSkillItemNurse({
  openModal,
  setOpenModal,
  fetchData,
}) {
  const { auth } = useAuth();
  const [data, setData] = useState({
    item: '',
    roles: ['nurse'],
  });

  const handlePost = () => {
    const formData = {
      item: data?.item,
      roles: data?.roles,
    };

    axios
      .post(`${url}`, formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        console.log('Item added successfully:', response);
        setData({ ...data });
        fetchData();
        toast.success('Item added successfully', {
          position: 'top-center',
          autoClose: 2000,
        });
      })
      .catch((error) => {
        console.log(error.message);
        toast.error(`${error.response.data.message}`, {
          position: 'top-center',
          autoClose: 2000,
        });
      });
  };

  return (
    <Dialog
      maxWidth='lg'
      fullwidth
      open={openModal}
      onClose={() => setOpenModal(false)}
    >
      <DialogTitle>Add new item</DialogTitle>
      <br />

      <DialogContent>
        <div className='flex justify-between'>
          <Box flex={1} mr={1}>
            <Typography fontFamily='Poppins'>Name*</Typography>
            <TextField
              fullWidth
              size='medium'
              variant='outlined'
              placeholder='Enter name'
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '6px',
                },
                mt: '5px',
              }}
              onChange={(e) =>
                setData({
                  ...data,
                  item: e.target.value,
                })
              }
            />
            <br />
            <br />
          </Box>
          <br />
        </div>
      </DialogContent>

      <DialogActions>
        <Button
          sx={{ textTransform: 'none' }}
          onClick={() => setOpenModal(false)}
          color='secondary'
        >
          Done
        </Button>
        <Button
          sx={{ textTransform: 'none' }}
          color='primary'
          onClick={handlePost}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
